<template>
  <div class="userinfo-orderList">
    <div class="title flex-center-start">
      {{ $t('she-zhi') }}
    </div>
    <div class="btn-list">
      <el-button @click="toPath('userInfoEditPassword')">{{
        $t('deng-lu-mi-ma')
      }}</el-button>
      <el-button @click="toPath('userInfoEditSafePassword')">{{
        $t('jiao-yi-mi-ma')
      }}</el-button>
      <el-button @click="toPath('userInfoAddress')">{{
        $t('shou-jian-di-zhi')
      }}</el-button>
    </div>
  </div>
</template>
<script>
import goodsList from '@/components/goodsList'
export default {
  components: {
    goodsList
  },
  data() {
    return {}
  },
  methods: {
    changeTab(i) {
      this.tabIndex = i
    },
    currentChange(page) {},
    toPath(path) {
      this.$router.push({
        name: path
      })
    }
  }
}
</script>